// eslint-disable-next-line no-redeclare,no-unused-vars
function buildFormLine(cont, param, key) {
  if (!key && param.key) key = param.key;
  var view = param.view;
  var settings = getFieldSettings(param, key);
  var object = param.object;
  var row = getObjectValue(data, [object, param.id]);
  var li;

  // ref line
  if (settings.refField && str_contains(settings.refField, '.')) {
    var refKey, refKey2;
    [refKey, refKey2] = settings.refField.split('.');
    return buildFormLine(
      cont,
      Object.assign({}, param, {
        object: getFieldSettings(param, refKey).reference,
        id: row[refKey],
        loadOnUndefined: true,
        label: settings.label,
      }),
      refKey2
    );
  }

  // full line
  if (
    settings.full &&
    !settings.half &&
    !param.filter &&
    !param.sidebar &&
    !cont.hasClass('card-body')
  ) {
    var contRow = $('<div class="row"></div>').insertAfter(cont.parent());
    if (!cont.children().length) cont.parent().remove();
    cont = $('<div class="col-12" data-site="left"></div>').appendTo(contRow);
  }

  if (cont && cont.is('ul'))
    li = $('<li class="list-group-item"></li>').appendTo(cont);
  else {
    li = $('<div class="form-group"></div>').appendTo(cont);
    if (param.view) li.addClass('mb-3');
  }
  li.els = [];
  li.key = key;
  li.attr('key', key);

  // hidden check
  if (isHidden(settings, row, Object.assign({nullAsEmpty: true}, param)))
    li.addClass('d-none');
  if (param.classLine) li.addClass(param.classLine);
  if (settings.hidden && isPlainObject(settings.hidden) && view) {
    if (!view.hiddenCheck) view.hiddenCheck = {};
    $.each(settings.hidden, function (key2) {
      if (!view.hiddenCheck[key2]) view.hiddenCheck[key2] = [];
      if (!inArray(key, view.hiddenCheck[key2]))
        view.hiddenCheck[key2].push(key);
    });
  }

  // block icon
  if (view && view.nav && settings.blockIcon && row[key + '_unseen']) {
    var ident = cont.parents('.card:first').attr('ident') + '_anker';
    view.nav
      .find('[ident=' + ident + ']')
      .append('<i class="icon fa-solid fa-comment"></i>');
  }

  // label
  var label = settings.label;
  if (param.label) label = param.label;
  else if (
    settings.label2 &&
    settings.label2Cond &&
    checkWhere(row, settings.label2Cond)
  )
    label = settings.label2;
  if (label && !settings.labelHide && !param.labelHide) {
    if (settings.labelConvert) {
      var label2 = convertPattern({
        text: settings.labelConvert,
        row: row,
        object: object,
      });
      if (label2) label = label2;
    }
    li.label = $(
      '<label class="form-label d-block">' + label + '</label>'
    ).appendTo(li);
    if (settings.tip) {
      var tip = nl2br(settings.tip);
      if (str_contains(tip, '__'))
        tip = convertPattern({text: tip, row: row, object: object});
      li.label.addClass('tip').addTooltip(tip);
    }
    if (settings.labelClass) li.label.addClass(settings.labelClass);
  }

  // add wiki help
  if (settings.wiki && !param.table) {
    var btn = $(
      '<span class="wikiBtn link"><i class="icon fa-regular fa-question-circle"></i></span>'
    ).on(click, function () {
      ajax({
        object: 'wiki',
        objectSub: 'public',
        send: {where: {object: object, key: key}},
        callback: function (xhr) {
          if (!xhr.ids) return;
          var id = xhr.ids[0];
          var row = xhr.data.wiki[id];
          if (row) {
            if (!row.title) row.title = 'Feldhilfe ' + label;
            if (param.modal)
              buildPopup({title: row.title, content: row.instruction});
            else buildHelp(row);
          }
        },
      });
    });
    if (li.label) li.label.append(btn);
    else li.append(btn);
  }

  // multi field
  if (settings.multiple) {
    var ul = $('<ul></ul>').appendTo(li);
    var rows = [{}];
    if (
      param.id != 'new' &&
      getObjectValue(data, [object, param.id, key, 'length'])
    )
      rows = data[object][param.id][key];
    else if (param.value && isArray(param.value)) rows = param.value;

    $.each(rows, function (index) {
      if (index && !rows[index]) return; // skip empty exceps first
      var li2 = $('<li></li>').appendTo(ul);
      li.element = buildField(li2, param, key, settings, 0, index, li);
      if (settings.fields) li.element.key = settings.fields[0];
      li.els.push(li.element);
      if (settings.json) {
        for (let i = 2; i < 6; i++)
          if (settings['field' + i]) {
            var el = buildField(li2, param, key, settings, i, index, li);
            if (!el) continue;
            if (settings.fields) el.key = settings.fields[i - 1];
            li.els.push(el);
          }
      } else if (!param.read)
        for (let i = 2; i < 6; i++)
          if (settings['field' + i])
            buildField(li2, param, key, settings, i, index, li);
      // li2.update = function (value, id) {
      // 	if (id) param.id = id;
      // 	li.replaceWith(buildFormLine(false, param, key));
      // };
    });
    if (!rows[0] && li.els.length > 1) li.els[0].parent().remove(); // remove first if empty
  }

  // normal field
  else {
    if (settings.prefix)
      $(
        '<span class="field-read input-group-text border-0" data-groupable="text">' +
          settings.prefix +
          '</span>'
      ).appendTo(li);
    li.element = buildField(li, param, key, settings, '', 0, li);
    for (var i = 2; i < 10; i++)
      if (settings['field' + i]) buildField(li, param, key, settings, i, 0, li);

    // add entity
    if (settings.entity && !param.noEntity && settings.field != 'progress') {
      var entity = settings.entity;
      if (entity != '%' && !str_contains(settings['entity'], '/'))
        entity = calculate(settings.entity, null, row, {
          object: object,
          currency: true,
        });
      if (settings.percent)
        li.element.entity = $(
          '<select class="input-group-text" data-groupable="entity"><option>' +
            entity +
            '</option><option>%</option></select>'
        )
          .appendTo(li)
          .on('change', function () {
            li.element.calculatePercent();
          });
      else
        $(
          '<span class="input-group-text" data-groupable="entity">' +
            entity +
            '</span>'
        ).appendTo(li);
    }
  }

  // input groups
  li.find('[data-groupable]').each(function () {
    if (
      $(this).next().is('[data-groupable]') &&
      !$(this).parent().hasClass('input-group')
    )
      $(this)
        .nextUntil(':not([data-groupable])')
        .addBack()
        .wrapAll('<div class="input-group"></div>');
  });

  if (li.label && li.element && li.element.is('input,select,textarea'))
    li.label.attr('for', li.element.attr('id'));
  if (settings.callback) settings.callback(li);
  if (param.focus)
    setTimeout(function () {
      $.each(li.els, function (i, el) {
        el.focus();
        if (param.click) el.trigger(click);
        if (!el.hasClass('read')) return false;
      });
    }, 100);
  if (param.loadOnUndefined && row && !defined(row[key]))
    getData(object, row.id, key);

  // context function
  if (settings.context) {
    var context = {};
    $.each(settings.context, function (type, value) {
      if (isPlainObject(value)) context[type] = value.label;
      else context[type] = value;
    });
    contextMenu(
      li,
      context,
      function (type) {
        if (settings.context[type] && settings.context[type].copy) {
          navigator.clipboard.writeText(
            convertPattern({
              text: settings.context[type].copy,
              object: object,
              row: row,
            })
          );
          showInfo(settings.context[type].success, 'success');
        } else if (settings.context[type]) {
          var pm = {
            object: object,
            objectSub: param.objectSub,
            id: row.id,
            post: true,
            send: {func: type, key: key},
          };
          if (isPlainObject(settings.context[type]))
            pm.send.funcValue = settings.context[type].value;
          ajax(pm);
        }
      },
      null,
      null,
      true
    );
  }
  if (param.bulk) {
    contextMenu(li, {remove: info.context.removeRow}, function (type) {
      if (type == 'remove') {
        li.element.val('').save();
      }
    });
  }

  if (test && user.admin && object && !param.filter)
    administrateField(li, param);
  if (user.admin && cont && (!param.table || param.bulk))
    contextMenu(li, {input: info.context.input}, function (type) {
      if (type == 'input') {
        var result = prompt(info.prompt.prompt, row[key]);
        if (isset(result)) {
          var send = {};
          send[key] = result;
          li.element.save(null, send);
        }
        return false;
      }
    });

  li.update = function () {
    var liNew = buildFormLine(cont, param, key);
    li.replaceWith(liNew);
    li = liNew;
  };

  li.focusNext = function () {
    var next = li.nextAll('.form-group');
    if (!next[0]) next = li.parent().next().find('.form-group');
    if (!next[0]) next = li.parents('.card-body').next().find('.form-group');
    next.find('input,textarea,button,select').first().focus();
  };

  li.rebuild = function () {
    var li2 = buildFormLine(cont, param, key);
    li.replaceWith(li2);
    li = li2;
  };

  return li;
}
